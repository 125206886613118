import { createRouter, createWebHashHistory } from "vue-router";
import routes from "./routes";
import { useTitle, useFavicon } from "@vueuse/core";
import { isVVV } from "@/helpers/siteIdentifier";

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeResolve((to) => {
  if (isVVV()) {
    useTitle(to.meta.title || "Very Viral Visuals");
    const logo = require("@/assets/VVV/vvv-favicon.png");
    useFavicon(logo);
  } else {
    useTitle(to.meta.title || "Creator Shield");
    const logo = require("@/assets/creator-shield-fav-icon.png");
    useFavicon(logo);
  }
});

export default router;
