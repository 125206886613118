<template>
  <router-view />
</template>

<script>
export default {
  name: "App"
};
</script>

<style>
#app {
  font-family: Roboto, Helvetica, Arial, sans-serif;
}
</style>
