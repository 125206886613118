import Home from "../views/Home.vue";
import { isVVV } from "@/helpers/siteIdentifier";

export default [
  { path: "/:pathMatch(.*)*", name: "NotFound", redirect: { name: "Login" } },
  {
    path: "/",
    name: "Home",
    component: Home,
    redirect: { name: "Login" },
  },
  {
    path: "/Login",
    name: "Login",
    component: () => {
      return isVVV()
        ? import("../views/VVV/VVVLoginPage.vue")
        : import("../views/v2/LoginNew.vue");
    },
    meta: { isVVV: isVVV() },
  },
  {
    path: "/Dashboard",
    name: "Dashboard",
    component: () => {
      return isVVV()
        ? import("../views/VVV/VVVDashboard.vue")
        : import("../views/v2/DashboardNew.vue");
    },
  },
  {
    path: "/UserSettings",
    name: "UserSettings",
    component: () => {
      return isVVV()
        ? import("../views/VVV/VVVUserSetting.vue")
        : import("../views/v2/UserSettingsNew.vue");
    },
  },
  {
    path: "/AssetLabels",
    name: "AssetLabels",
    component: () => import("../views/v2/AssetsLabelsNew"),
  },
  {
    path: "/AssetLabelSettings/:id",
    name: "AssetLabelSettings",
    component: () => import("../views/v2/AssetsLabelSettingsNew"),
  },
  {
    path: "/Assets",
    name: "Assets",
    component: () => import("../views/v2/AssetsNew"),
  },
  {
    path: "/UserManagement",
    name: "UserManagement",
    component: () => import("../views/v2/UserManagementNew.vue"),
  },
  {
    path: "/UploadAnalytics",
    name: "UploadAnalytics",
    component: () => import("../views/v2/UploadAnalyticsNew.vue"),
  },
  {
    path: "/Users/:id/MonthlyReport",
    name: "MonthlyReport",
    component: () => import("../views/v2/MonthlyReportNew"),
  },
  {
    path: "/Users/Add",
    name: "AddUser",
    component: () => import("../views/v2/AddUserNew"),
  },
  {
    path: "/Users/:id/Adjustments",
    name: "Deductions",
    component: () => import("../views/v2/UserAdjustmentsNew"),
    props: true,
  },
  {
    path: "/Users/:id/AdjustmentsNew",
    name: "AdjustmentsNew",
    component: () => import("../views/v2/UserAdjustmentsNew"),
    props: true,
  },
  {
    path: "/Users/:id/AdjustmentsNew",
    name: "AdjustmentsNew",
    component: () => import("../views/v2/UserAdjustmentsNew"),
    props: true,
  },
  {
    path: "/Users/:id/PartnerPercentage",
    name: "PartnerPercentage",
    component: () => import("../views/v2/PartnerPercentageNew"),
    props: true,
  },
  {
    path: "/Users/:link/Activate",
    name: "Register",
    component: () => import("../views/v2/ActivateUserNew"),
  },
  {
    path: "/Users/:link/Reset",
    name: "Reset",
    component: () => import("../views/v2/ResetPasswordNew"),
  },
  {
    path: "/Users/:id/Update",
    name: "UpdateUser",
    component: () => import("../views/v2/UserUpdateNew"),
  },
  {
    path: "/DailyVideoUpdate/Management",
    name: "DailyVideoUpdatesManagement",
    component: () => import("../views/v2/CreatorsNotificationManagementNew"),
  },
  {
    path: "/DailyVideoUpdate/Add",
    name: "AddCreator",
    component: () => import("../views/v2/AddCreatorNotificationNew"),
  },
  {
    path: "/DailyVideoUpdate/Notifications",
    name: "Notifications",
    component: () => import("../views/v2/DailyVideoUpdateNotificationListNew"),
  },
  {
    path: "/DailyVideoUpdate",
    name: "DailyVideoUpdate",
    component: () => import("../views/v2/DailyVideoUpdateNew"),
  },
  {
    path: "/DailyVideoUpdate/:id/Update",
    name: "UpdateCreator",
    component: () => import("../views/v2/UpdateCreatorNotificationNew"),
  },
  {
    path: "/VideoUpdateReport",
    name: "VideoUpdateReport",
    component: () => import("../views/v2/VideoUpdateReportNew"),
  },
  {
    path: "/Collections",
    name: "Collections",
    component: () => import("../views/v2/CollectionsNew"),
  },
  {
    path: "/Collections/:id/assets",
    name: "CollectionsAssets",
    component: () => import("../views/v2/CollectionsAssetsNew"),
  },
  {
    path: "/Collections/:id/assets/:assetId",
    name: "CollectionsAssetsView",
    component: () => import("../views/v2/CollectionsAssetsViewNew"),
  },
  {
    path: "/Assets-Upload",
    name: "AssetsUpload",
    component: () => import("../views/v2/AssetsUploadNew"),
  },
  {
    path: "/Copies",
    name: "Copies",
    component: () => import("../views/v2/CopiesNew"),
  },
  {
    path: "/Whitelist",
    name: "Whitelist",
    component: () => import("../views/v2/WhitelistNew"),
  },
  {
    path: "/NewClaim",
    name: "NewClaim",
    component: () => import("../views/VVV/NewClaim/NewClaim"),
  },
  {
    path: "/ClaimsList",
    name: "ClaimsList",
    component: () => import("../views/VVV/ClaimsList/ClaimsList"),
  },
  {
    path: "/BulkClaims",
    name: "BulkClaims",
    component: () => import("../views/VVV/BulkClaims"),
  },
];
